import React from 'react';
import './autocomplete.css';

class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            matches: [],
            query: "",
            selected: false
        };

        if(props.initialValue){
            this.state.query = props.initialValue;
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.initialValue !== this.props.initialValue){
            this.setState({query: nextProps.initialValue});
        }
    }

    handleKeyPress(event) {
        const { activeIndex, matches } = this.state;

        switch (event.which) {
            case 13: // Enter key
                if (matches.length) {
                    this.handleSelection(undefined, matches[activeIndex]);
                }
                break;
            case 38: // Up arrow
                this.setState({
                    activeIndex: activeIndex >= 1 ? activeIndex - 1 : 0
                });
                break;
            case 40: // Down arrow
                this.setState({
                    activeIndex:
                        activeIndex < matches.length - 1
                            ? activeIndex + 1
                            : matches.length - 1
                });
                break;
            default:
                break;
        }
    }

    handleSelection(event, selection) {
        clearTimeout(this.blurTimeout);
        if(event){
            event.preventDefault();
        }
        this.props.onSelection(selection);
        this.setState({
            activeIndex: 0,
            query: selection,
            matches: [],
            selected: true
        });
    }

    handleBlur(event) {
        
        //this.props.onSelection
        
        this.blurTimeout = setTimeout(()=>{
            if(this.state.matches && this.state.matches.length > 0){
                this.props.onSelection(this.state.matches[0]);
                this.setState({query: this.state.matches[0]});
            }
            this.setState({
                matches: []
            });
        },100);
    }

    updateQuery(e) {
        const { data } = this.props;
        let count = 10;
        if (!this.state.selected) {
            const query = e.target.value;
            const filtered_data = query.length >= 2
                ? data.filter(
                    item => {
                        if (item.toUpperCase().indexOf(query.toUpperCase()) == 0) {
                            return count-- > 0;
                        }
                        return false;
                    }
                )
                : [];
            this.setState({
                matches:
                    filtered_data,
                query
            });
        } else {
            if (e.nativeEvent.inputType === "deleteContentBackward") {
                this.setState({
                    matches: [],
                    query: "",
                    selected: false
                });
            }
        }
    }

    render() {
        const { label, name, placeholder } = this.props;
        const { activeIndex, matches, query } = this.state;

        return (
            <div className="field" style={{width: this.props.width, display: "inline-block"}}>
                {label && <label className="label">{label}</label>}
                <div className="control">
                    <div className={`dropdown ${matches.length > 0 ? "is-active" : ""}`}>
                        <div className="dropdown-trigger">
                        <span style={{width: this.props.width, display:"inline-block", marginRight: "0.2rem"}} className="control has-icons-right">
                        <input
                                type="text"
                                className="input"
                                name={name}
                                value={query}
                                onChange={this.updateQuery}
                                onKeyDown={this.handleKeyPress}
                                onBlur={this.handleBlur}
                                placeholder={placeholder}
                                style={{width: this.props.width}}
                            />
                    <span className="icon is-small is-right">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                </span> 
                            
                        </div>
                        <div className="dropdown-menu"
                                style={{width: this.props.width, overflow: "hidden"}}>
                            {matches.length > 0 && (
                                <div className="dropdown-content">
                                    {matches.map((match, index) => (
                                        <a
                                            className={`dropdown-item ${index === activeIndex ? "is-active" : ""
                                                }`}
                                            href="/"
                                            key={match + Math.random()}
                                            onClick={event => this.handleSelection(event, match)}
                                        >
                                            {match}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Autocomplete;
