import React from 'react';

class MultilineTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: this.props.value};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    if(this.props.valueCallback){
      this.props.valueCallback(event.target.value);
    }
    //event.target.style.height = event.target.scrollHeight+'px';
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.value !== this.props.value){
      this.setState({value: nextProps.value});
    }
  }

  render() {
    var value = this.state.value.replaceAll('\\n', '\n');
    console.log(this.props.value);
    return <textarea onChange={this.handleChange} value={value} class="textarea"></textarea>;
  }
}

export default MultilineTextarea;
