import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import GoogleMapReact from 'google-map-react';
import { BASE_PATH, META_DATA_MAP, PROPERTY_MAP, linebreakToHTML, htmlEnc, osmAdress2GermanAddress, osmAdress2meta } from '../utils';
import MultilineTextarea from './reusableComponents/textarea';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/bank';
import homeIcon from '@iconify/icons-mdi/home';
import { Carousel } from 'react-responsive-carousel';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import './entry.css';


class Entry extends React.Component {
  state = {
    gmrKey: "gmr" + Math.random(),
    contactModal: false,
  };

  constructor(props) {
    super(props);

    this.addressInput = new React.createRef();
    this.toggleImageUploadModal = this.toggleImageUploadModal.bind(this);
    this.newImageChanged = this.newImageChanged.bind(this);
    this.addNewImage = this.addNewImage.bind(this);
    this.submitLocationChange = this.submitLocationChange.bind(this);
    this.changeLocationCoords = this.changeLocationCoords.bind(this);
    this._onBoundsChange = this._onBoundsChange.bind(this);
    this.updateLocationProperty = this.updateLocationProperty.bind(this);
    this.moveImage = this.moveImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.addTag = this.addTag.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }

  setData = (data) => {
    this.setState(() => ({
      text: data.text,
      kittens: data.image
    }));
  }

  getMembersData = () => {
    const cookies = new Cookies();
    const token = cookies.get('auth');
    if (token) {
      const authHeaders = {
        headers: { 'x-auth': token }
      };

    }

    this.fetchLocationData(this.props.match.params.id);
  }

  isOwner() {
    if (this.state.location && this.state.location.id === "new") {
      return true;
    }
    return (this.state.location.user_id === this.props.user.id);
  }

  fetchLocationData(entry_id) {
    if (entry_id === "new") {
      this.setState({ editing: true, location: { id: "new", "name": undefined, "lat": undefined, "lon": undefined, "location_type": 1, "images": [], "meta": {}, catering_choice: 1 } });
      return;
    }
    fetch(BASE_PATH + '/location.php?id=' + entry_id)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data && data.images) {
          data.images.sort((a, b) => a.image_index - b.image_index);
        }
        if (data && data.meta && data.meta.length === 0) {
          data.meta = {};
        }
        this.setState({ location: data });
      });
  }

  componentWillMount() {
    this.getMembersData();
  }

  toggleImageUploadModal() {
    if (this.state.image_modal) {
      this.setState({ image_modal: undefined, previewUploadImage: undefined });
    } else {
      this.setState({ image_modal: true });
    }
  }

  newImageChanged(e) {
    const input = e.target;
    const self = this;
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        var img = new Image;
        img.onload = function () {
          const max_dimension_size = 1024;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          if (img.width > img.height) {
            canvas.width = 1024;
            canvas.height = canvas.width * (img.height / img.width);
          } else {
            canvas.height = 1024;
            canvas.width = canvas.height * (img.width / img.height);
          }
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          var data = canvas.toDataURL('image/jpeg', 0.8);
          self.setState({ previewUploadImage: data });
        };
        img.src = e.target.result;
      }

      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  addNewImage() {
    const images = this.state.location.images;
    images.push({ image_data: this.state.previewUploadImage });
    const location = this.state.location;
    location.images = images;
    this.setState({ location: location, image_modal: undefined, previewUploadImage: undefined });
  }

  moveImage(index, dir) {
    if (index + dir < 0 || index + dir >= this.state.location.images.length) {
      return;
    }
    const location = this.state.location;
    const curElem = this.state.location.images[index];
    location.images[index] = location.images[index + dir];
    location.images[index + dir] = curElem;

    location.images.forEach((e, i) => { e.image_index = i });
    this.setState({ location: location });
  }

  deleteImage(index) {
    const location = this.state.location;
    location.images.splice(index, 1);
    if (this.state.galleryImage >= location.images.length) {
      this.setState({ galleryImage: location.images.length - 1 });
    }
    this.setState({ location: location });
  }

  submitLocationChange() {

    const locationName = document.getElementById("locationName").value;
    const locationDescription = document.getElementById("locationDescription").value;
    const images = this.state.location.images;
    let error = false;

    if (error) { return; }
    const data = {
      "locationId": this.state.location.location_id,
      "locationName": locationName,
      "locationDescription": locationDescription,
      "lon": this.state.location.lon,
      "lat": this.state.location.lat,
      "locationMaxSeats": this.state.location.max_seats,
      "locationEstimatedCostBaseMin": this.state.location.estimated_cost_base_min,
      "locationEstimatedCostBaseMax": this.state.location.estimated_cost_base_max,
      "locationEstimatedCostPerGuestMin": this.state.location.estimated_cost_per_guest_min,
      "locationEstimatedCostPerGuestMax": this.state.location.estimated_cost_per_guest_max,
      "locationCateringChoice": this.state.location.catering_choice,
      "images": JSON.stringify(images),
      "meta": JSON.stringify(this.state.location.meta)
    };
    if (this.state.location.address) {
      data["locationAddress"] = this.state.location.address;
    }
    if (this.state.location.contact_name) {
      data["locationContactName"] = this.state.location.contact_name;
    }
    if (this.state.location.contact_email) {
      data["locationContactEmail"] = this.state.location.contact_email;
    }
    if (this.state.location.contact_phone) {
      data["locationContactPhone"] = this.state.location.contact_phone;
    }

    var fd = new FormData();
    //very simply, doesn't handle complete objects
    for (var i in data) {
      fd.append(i, data[i]);
    }
    // // Submit to server
    const self = this;
    fetch(BASE_PATH + '/editLocation.php', {
      method: 'POST',
      body: fd
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        const newLocId = data["locationId"];
        window.location.href = "/entry/" + newLocId;
        self.setState({ editing: undefined });
        self.fetchLocationData(newLocId);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  changeLocationCoords(e) {
    const address = this.addressInput.current.value;
    fetch("https://nominatim.openstreetmap.org/search.php?q=" + htmlEnc(address) + "&format=jsonv2&addressdetails=1")
      .then(response => response.json())
      .then(data => {
        if (data.length == 1) {
          const location = this.state.location;
          location.lat = parseFloat(data[0].lat);
          location.lon = parseFloat(data[0].lon);
          location.address = osmAdress2GermanAddress(data[0].address);
          this.setState({ location: location, gmrKey: "gmr" + Math.random() });
        } else if (data.length > 1) {
          this.setState({ locationSearchError: "Die Suche führte zu keinen eindeutigen Koordinaten." });
        } else {
          this.setState({ locationSearchError: "Unter \"" + address + "\" konnten keine Koordinaten gefunden werden. Unterstützte Suchbegriffe sind unter Anderem Ortsbezeichnungen/klassische Addressformate." });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  locationChanged() {
    fetch("https://nominatim.openstreetmap.org/reverse.php?lat=" + this.state.location.lat + "&lon=" + this.state.location.lon + "&format=jsonv2")
      .then(response => response.json())
      .then(data => {
        const location = this.state.location;
        location.address = osmAdress2GermanAddress(data.address);
        osmAdress2meta(data.address, location.meta);
        this.setState({ location: location });
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  _onBoundsChange(center, zoom, bounds, marginBounds) {
    if (!this.state.mapsCenter || this.state.mapsCenter[0] != center[0] || this.state.mapsCenter[1] != center[1]) {
      this.setState({ mapsCenter: center });
    }
  }

  addTag(e, p) {
    const location = JSON.parse(JSON.stringify(this.state.location));
    if (e.target.checked) {
      location.meta[p.key] = 1;
    } else {
      location.meta[p.key] = undefined;
    }
    this.setState({ location: location });
  }

  updateLocationProperty(e, prop) {
    const value = e.target.value;
    const location = this.state.location;
    location[prop] = value;
    this.setState({ location: location });
  }

  getEntryContentArea() {
    const self = this;
    let result = [];

    // important information
    let overviewContent;
    if (this.state.location.id !== "new" && !this.state.editing && (!this.state.location.meta || Object.keys(this.state.location.meta).length === 0)) {
      overviewContent = <i>Keine Informationen vorhanden.</i>;
    } else {

      let price = <i>Keine Preisinformationen vorhanden</i>;
      if (this.state.location.estimated_cost_base_min) {
        let per_guest_min = this.state.location.estimated_cost_per_guest_min ? this.state.location.estimated_cost_per_guest_min : 0;
        let per_guest_max = this.state.location.estimated_cost_per_guest_max ? this.state.location.estimated_cost_per_guest_max : 0;
        let base_min = this.state.location.estimated_cost_base_min ? this.state.location.estimated_cost_base_min : 0;
        let base_max = this.state.location.estimated_cost_base_max ? this.state.location.estimated_cost_base_max : 0;

        price = <i>Anzahl Gäste zur Preisberechnung eingeben</i>;
        if (this.props.numberOfGuests) {
          price = "~ " + Math.floor(base_min / (this.props.numberOfGuests) + per_guest_min) + " - " + Math.ceil(base_max / (this.props.numberOfGuests) + per_guest_max) + " € pro Gast (" + (base_min + (this.props.numberOfGuests) * per_guest_min) + " - " + (base_max + (this.props.numberOfGuests) * per_guest_max) + " € insgesamt)";
        }
      }

      overviewContent = <div className="is-flex" style={{ flexDirection: "column" }}><div className="overview-row enforce-full-width">
        <i class="fas fa-users overview-symbol mr-4"></i>
        <span className="subtitle">maximal {this.state.location.max_seats} Gäste</span>
      </div>
        <div className="overview-row enforce-full-width">
          <i class="fas fa-money-bill-wave overview-symbol mr-4"></i>
          <span className="subtitle">{price}</span>
        </div>
        <div className="overview-row enforce-full-width">
          <i class="fas fa-utensils overview-symbol mr-4"></i>
          <span className="subtitle">{this.state.location.catering_choice ? (this.state.location.catering_choice === 1 ? "Freie Catering-Wahl" : "Catering durch Location vorgegeben") : <i>Catering-Optionen unbekannt</i>}</span>
        </div></div>;

      if (this.state.editing) {
        overviewContent = <div>
          <div className="field">
            <label className="label">Maximale Anzahl Gäste</label>
            <div className="control">
              <input className="input inline-input" style={{ width: "15rem" }} type="number" onChange={(e) => { const location = this.state.location; location.max_seats = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.max_seats ? this.state.location.max_seats : ""} placeholder="maximale Anzahl Gäste" />
            </div>
          </div>
          <div className="field">
            <label className="label">Grundkosten (ungefähr)</label>
            <div className="control" style={{ display: "flex", alignItems: "center" }}>
              <input className="input inline-input" type="number" onChange={(e) => { const location = this.state.location; location.estimated_cost_base_min = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.estimated_cost_base_min ? this.state.location.estimated_cost_base_min : ""} placeholder="min" /> bis <input className="input inline-input" onChange={(e) => { const location = this.state.location; location.estimated_cost_base_max = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.estimated_cost_base_max ? this.state.location.estimated_cost_base_max : ""} type="number" placeholder="min" /> €
            </div>
          </div>
          <div className="field">
            <label className="label">Catering</label>
            <div className="control">
              <div class="select">
                <select onChange={(e) => { const location = this.state.location; location.catering_choice = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.catering_choice}>
                  <option value="1">Freie Catering-Wahl</option>
                  <option value="2">Catering durch Location vorgegeben</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Kosten pro Gast (inklusive Catering falls angeboten; ungefähr)</label>
            <div className="control" style={{ display: "flex", alignItems: "center" }}>
              <input className="input inline-input" type="number" onChange={(e) => { const location = this.state.location; location.estimated_cost_per_guest_min = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.estimated_cost_per_guest_min ? this.state.location.estimated_cost_per_guest_min : ""} placeholder="min" /> bis <input className="input inline-input" onChange={(e) => { const location = this.state.location; location.estimated_cost_per_guest_max = parseInt(e.target.value); this.setState({ location: location }); }} value={this.state.location.estimated_cost_per_guest_max ? this.state.location.estimated_cost_per_guest_max : ""} type="number" placeholder="min" /> €
            </div>
          </div>
        </div>;
      }
    }
    let important = <div className="section is-small pb-0">
      <h4 className="title is-title-4">Übersicht</h4>
      {overviewContent}
    </div>;
    result.push(important);

    // description
    let description = <div className="section is-small pb-0">
      <h4 className="title is-title-4">Beschreibung</h4>
      {this.state.editing ? <textarea placeholder="Kurzbeschreibung der Location" className="textarea location-description" id="locationDescription" rows="4" cols="50">
        {this.state.location.description}
      </textarea> : (this.state.location.description.length > 0 ? linebreakToHTML(this.state.location.description) : <i>Keine Beschreibung vorhanden.</i>)}
    </div>;
    result.push(description);

    // tags
    let tags;
    if (this.state.location.id !== "new" && !this.state.editing && (!this.state.location.meta || Object.keys(this.state.location.meta).length === 0)) {
      tags = <i>Keine Informationen vorhanden.</i>;
    } else {
      tags = <div className="flex-distribute">{PROPERTY_MAP.filter(p => this.state.editing || this.state.location.meta[p.key] === 1).map(p => <div className="overview-row">
        {this.state.editing ? <input type="checkbox" onChange={(e) => this.addTag(e, p)} checked={this.state.location.meta[p.key] != undefined} /> : ""}
        <i className={p.icon + " overview-symbol"}></i>
        <span>{p.desc}</span>
      </div>)}</div>;
    }
    let ausstattung = <div className="section is-small pb-0">
      <h4 className="title is-title-4">Ausstattung</h4>
      {this.state.editing ? <p>Wählen Sie die zutreffenden Attribute aus:</p> : ""}
      {tags}
    </div>;
    result.push(ausstattung);

    return result;
  }

  submitContact(){
    const data = {
      "email": this.state.contactFromMail,
      "request": this.state.contactRequest
    };
    var fd = new FormData();
    //very simply, doesn't handle complete objects
    for (var i in data) {
      fd.append(i, data[i]);
    }
    // // Submit to server
    const self = this;
    fetch(BASE_PATH + '/inquiry.php', {
      method: 'POST',
      body: fd
    })
      .then(response => response.json())
      .then(data => {
        console.log('Inquiry Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  render() {
    if (!this.state.location) {
      return "loading...";
    }
    let typeDesc = "";
    switch (this.state.location.location_type) {
      case 1: typeDesc = "Festhalle"; break;
      case 2: typeDesc = "Historische Location"; break;
      case 3: typeDesc = "Gesellschaftslocation"; break;
    }

    let imageToolbar = [];
    let images = [];
    if (this.state.location.images) {
      images = this.state.location.images.slice(0, 5).map((img, i) => {
        let img_data = "/location_image.php?id=" + img.id;
        if (img.image_data) {
          img_data = img.image_data;
        }
        return <div onClick={() => { this.setState({ galleryImage: i }); }} className={"previewImage previewImage" + (i + 1)} style={{ backgroundImage: "url(" + img_data + ")" }}></div>;
      });
      if (this.state.location.images.length === 0) {
        images.push(<div key="slide" className="image-placeholder">
          <div style={{ textAlign: "center" }}>
            {this.state.location.images.length === 0 ? "Noch keine Bilder vorhanden." : <p>Weitere Bilder hinzufügen?</p>}<br />
            {this.state.editing ? <button className="button" onClick={this.toggleImageUploadModal}>Bilder hinzufügen</button> : ""}
          </div>
        </div>);
      } else {
        if (this.state.editing) {
          imageToolbar.push(
            <button onClick={this.toggleImageUploadModal} className="add-images-button button">Bilder hinzufügen</button>);
        }
        imageToolbar.push(<button onClick={() => { this.setState({ galleryImage: 0 }) }} className="add-images-button button">{this.state.location.images.length} {this.state.location.images.length != 1 ? "Bilder" : "Bild"}</button>);
      }
    }

    images = <div className={"imagePreviewContainer " + ("total-images-" + Math.min(5, images.length))}>
      {images}
      <div className="images-toolbar-button ">{imageToolbar}</div>
    </div>;

    const location = {
      lat: this.state.location.lat ? this.state.location.lat : 51.163361111111,
      lng: this.state.location.lon ? this.state.location.lon : 10.447683333333
    }

    const zoomLevel = this.state.location.lat ? 14 : 5;

    return (
      <div className="section">
        <section className="container is-max-desktop">
          {this.isOwner() && !this.state.editing ? <button className="button is-pulled-right is-primary" onClick={() => { this.setState({ editing: true }); if (!this.state.location.catering_choice || this.state.location.catering_choice === 0) { const location = JSON.parse(JSON.stringify(this.state.location)); location.catering_choice = 1; this.setState({ location: location }); } }}>Seite bearbeiten</button> : ""}
          <h2 className="title">{this.state.editing ? <input type="text" id="locationName" className="input title" placeholder="Name der Location" onChange={(e) => { this.updateLocationProperty(e, "name") }} value={this.state.location.name} /> : this.state.location.name}</h2>
          <h3 className="subtitle">{typeDesc}&nbsp;</h3>

          {images}
          <div className="main-entry-area">
            {!this.state.editing ? <div className="entry-contact-area">
              <button className="button is-primary is-fullwidth" onClick={()=>{this.setState({contactModal: true});}} type="button">Location anfragen</button>
            </div> : ""}
            <div className="entry-content-area">
              {this.getEntryContentArea()}
            </div>
          </div>
          <div className="section is-small">
            <h4 className="title is-title-4">Ort</h4>
            {this.state.editing ?
              <div>
                <p className="mt-4 mb-4">Standort der Location durch Eingabe der Adresse oder durch einen Klick auf der Karte auswählen.<br />
                  Über Ziehen mit der Maus und das Mausrad, kann innerhalb der Karte navigiert werden.</p>
                <div className="is-flex">
                  <input className="input is-grow-2" type="text" placeholder="Adresse der Location" ref={this.addressInput}></input>
                  <button onClick={this.changeLocationCoords} className="button">Suchen</button>
                </div>
                {this.state.locationSearchError ? <div className="notification is-warning mt-4">
                  <button className="delete" onClick={() => { this.setState({ locationSearchError: undefined }) }}></button>
                  {this.state.locationSearchError}
                </div> : ""}
              </div>
              : ""}
            <p className="mt-4">{this.state.location.address}</p>
            <div className="location-map-environments mt-4">
              <GoogleMapReact
                key={this.state.gmrKey}
                bootstrapURLKeys={{ key: 'AIzaSyD-0BG7Ow9YwLIZHwkHE35IvJqZIdq_9Dg' }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
                onBoundsChange={this._onBoundsChange}
                options={{ gestureHandling: "greedy" }}
                onClick={(e) => {
                  if (!this.state.editing) {
                    return;
                  }
                  const location = this.state.location;
                  location.lat = e.lat;
                  location.lon = e.lng;
                  this.locationChanged();
                  this.setState({ previewId: undefined })
                }}
              >
                {this.state.location.lat ? <HomePin
                  onHover={() => { }}
                  lat={location.lat}
                  lng={location.lng}
                  text={""}
                /> : ""}
              </GoogleMapReact></div>
          </div>
          {this.state.editing ?
            <div className="section is-small">
              <h4 className="title is-title-4">Kontakt</h4>
              <div className="field">
                <label className="label">Ansprechpartner</label>
                <div className="control">
                  <input className="input" type="text" placeholder="Ansprechpartner" onChange={(e) => { this.updateLocationProperty(e, "contact_name") }} value={this.state.location.contact_name ? this.state.location.contact_name : ""} />
                </div>
              </div>
              <div className="field">
                <label className="label">E-Mail-Adresse</label>
                <div className="control">
                  <input className="input" type="text" placeholder="E-Mail-Adresse" onChange={(e) => { this.updateLocationProperty(e, "contact_email") }} value={this.state.location.contact_email ? this.state.location.contact_email : ""} />
                </div>
                {this.state.contactEmailError ? <p className="help is-danger">{this.state.contactEmailError}</p> : ""}
              </div>
              <div className="field">
                <label className="label">Telefonnummer (optional)</label>
                <div className="control">
                  <input className="input" type="text" placeholder="Telefonnummer" onChange={(e) => { this.updateLocationProperty(e, "contact_phone") }} value={this.state.location.contact_phone ? this.state.location.contact_phone : ""} />
                </div>
                {this.state.contactPhoneError ? <p className="help">{this.state.contactPhoneError}</p> : ""}
              </div>
            </div>
            : ""}
          {this.state.editing ? <button onClick={this.submitLocationChange} className="button is-primary is-fullwidth mt-6 mb-6">Location-Eintrag speichern</button> : ""}
        </section>

        <div className={"modal " + (this.state.image_modal ? " is-active" : "")}>
          <div className="modal-background" onClick={this.toggleImageUploadModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Bild hinzufügen</p>
              <button className="delete" aria-label="close" onClick={this.toggleImageUploadModal}></button>
            </header>
            <section className="modal-card-body image-upload-modal-content">
              <div className="file is-boxed is-flex-grow-1">
                <label className="file-label">
                  <input type='file' id="imgInp" className="is-hidden" onChange={this.newImageChanged} />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">
                      Wähle ein Bild aus ...
                    </span>
                  </span>
                </label>
              </div>

              {this.state.previewUploadImage ? <div className="upload-image-preview-container"><img className="upload-image-preview" src={this.state.previewUploadImage} alt="your image" /></div> : ""}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" disabled={!this.state.previewUploadImage} onClick={this.addNewImage}>Bild hinzufügen</button>
              <button className="button" onClick={this.toggleImageUploadModal}>Abbrechen</button>
            </footer>
          </div>
        </div>
        <div className={"modal " + (typeof this.state.galleryImage !== "undefined" ? "is-active" : "")}>
          <div className="modal-background"></div>
          <div style={{ textAlign: "center" }}>{this.state.galleryImage < this.state.location.images.length - 1 ? <button onClick={() => { this.setState({ galleryImage: this.state.galleryImage + 1 }) }} className="image-gallery-right button  is-rounded">
            <span className="icon is-small">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button> : ""}
            {this.state.galleryImage > 0 ? <button onClick={() => { this.setState({ galleryImage: this.state.galleryImage - 1 }) }} className="image-gallery-left button  is-rounded">
              <span className="icon is-small">
                <i className="fas fa-arrow-left"></i>
              </span>
            </button> : ""}
            <div className="fixed-center-center" onClick={() => { this.setState({ galleryImage: undefined }) }}>
              {this.state.location.images && typeof this.state.galleryImage !== "undefined" ? <img onClick={(e) => { e.stopPropagation(); }} className="gallery-image" src={this.state.location.images[this.state.galleryImage].image_data ? this.state.location.images[this.state.galleryImage].image_data : ("/location_image.php?id=" + this.state.location.images[this.state.galleryImage].id)} alt="" /> : ""}
              {this.state.editing ? <div>
                {this.state.galleryImage > 0 ? <button onClick={(e) => { e.stopPropagation(); this.moveImage(this.state.galleryImage, -1); }} className="button  is-rounded">
                  <span className="icon is-small">
                    <i class="far fa-arrow-alt-circle-left"></i>
                  </span>
                  <span>nach vorne schieben</span>
                </button> : ""}
                {this.state.galleryImage < this.state.location.images.length - 1 ? <button onClick={(e) => { e.stopPropagation(); this.moveImage(this.state.galleryImage, +1); }} className="button  is-rounded">
                  <span className="icon is-small">
                    <i class="far fa-arrow-alt-circle-right"></i>
                  </span>
                  <span>nach hinten schieben</span>
                </button> : ""}
                <button onClick={(e) => { e.stopPropagation(); this.deleteImage(this.state.galleryImage); }} className="button  is-rounded">
                  <span className="icon is-small">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span>Löschen</span>
                </button>
              </div> : ""}
            </div>
          </div>
          <button className="modal-close is-large" onClick={() => { this.setState({ galleryImage: undefined }) }} aria-label="close"></button>
        </div>

        
        <div id="regionFailModal" class={"modal "+(this.state.contactModal?" is-active ":"")}>
          <div class="modal-background"></div>
          <button class="modal-close is-large" aria-label="close"  onClick={()=>{this.setState({contactModal: false});}}></button>
          <div class="modal-content">
            <div class="box">
              <section class="section">
                <h1 class="title is-4">Anfrage erstellen</h1>
                <div class="field">
                  <label class="label">Deine Email-Adresse</label>
                  <div class="control has-icons-left has-icons-right">
                    <input class="input" type="email" onChange={(e)=>{this.setState({contactFromMail: e.target.value})}} placeholder="Email-Adresse" disabled={this.props.user&&this.props.user.email ? true : false} value={this.props.user&&this.props.user.email ? this.props.user.email : this.state.contactFromMail}/>
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label">Nachricht</label>
                  <div class="control">
                    <MultilineTextarea valueCallback={(v)=>{this.setState({contactRequest: v})}} value="Sehr geehrte Damen und Herren,\n
auf der Suche nach einer Hochzeitslocation sind wir auf hochzeit-suche.de auf Ihre Location aufmerksam geworden.
Die Location hat unser Interesse geweckt und wir würden daher die Location gerne besichtigen.
Sollten Sie weitere Informationen zu Preisinformationen oder Verfügbarkeit besitzen, würden wir uns freuen wenn Sie uns diese mitteilen könnten.\n
Mit freundlichen Grüßen\n
Das zukünftige Ehepaar "/>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" onChange={(e)=>{this.setState({contactDSGVO: e.target.checked})}}  style={{marginRight: "0.5rem"}}/>
                      Ich stimme der Verarbeitung meiner Daten im Einklang mit der <a href="#">Datenschutzerklärung</a> zu.
                    </label>
                  </div>
                </div>
                <button type="button" className="button is-primary is-fullwidth" disabled={!this.state.contactDSGVO} onClick={this.submitContact}>Anfrage absenden</button>
              </section>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

class HomePin extends Component {
  render() {
    return <div onMouseEnter={() => { this.props.onHover(true); }} onMouseLeave={() => this.props.onHover(false)} className={"pin has-background-info" + (this.props.hovered ? "has-background-link" : "")}>
      <Icon icon={homeIcon} color={"white"} className="pin-icon" />
    </div>;
  }
}

export default Entry;
