import React from 'react';
import Cookies from 'universal-cookie';
import './cookieconsent.css';

class CookieConsent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }


    render() {
        
        const cookies = new Cookies();
        const active = !cookies.get('cookie-setting');
        const allowGA = cookies.get('cookie-setting') === "all";
        return (
            <div className={"modal "+(active?"is-active":"")} style={{justifyContent: "flex-end"}}>
                <div className="modal-card"  style={{width: "100%"}}>
                    <section className="modal-card-body">
                        Unsere Website erhebt Daten zur Anbietung und Verbesserung unseres Services:
                        <div className="content">
                            <ul>
                                <li>notwendige Cookies (Login, etc.)</li>
                                <li>analytisches Cookies (Google Analytics)</li>
                            </ul>
                        </div>

                        
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-equal-distributed" onClick={()=>{cookies.set('cookie-setting', 'necessary', {maxAge: 86400*365*2}); this.setState({update: Math.random()})}}>Nur notwendige</button>
                        <button className="button is-equal-distributed is-success" onClick={()=>{cookies.set('cookie-setting', 'all', {maxAge: 86400*365*2}); this.setState({update: Math.random()})}}>Alle erlauben</button>
                    </footer>
                </div>
            </div>
        );
    }
}

export default CookieConsent;


/**/