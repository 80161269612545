import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { BASE_PATH } from '../utils';
import { getUserDataService } from '../userDataService';

class Login extends React.Component {
  state = {
    error: undefined
  };
  handelSignup = (e, props) => {
    e.preventDefault();

    const formElements = e.target.elements;
    const email = formElements.email.value.trim();
    const password = formElements.password.value.trim();
    const error = false;

    const data = {
      "email": email,
      "password": password
    };
    var fd = new FormData();
    //very simply, doesn't handle complete objects
    for (var i in data) {
      fd.append(i, data[i]);
    }
    // // Submit to server
    const self = this;
    fetch(BASE_PATH + '/login.php', {
      method: 'POST',
      body: fd
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        if (data.error) {
          switch (data.code) {
            case 1:
              self.setState({ credentialsError: "Die eingegebenen Daten stimmen mit keinem Nutzeraccount überein." });
              break;
            default:
              break;
          }
        } else if (data.success) {
          getUserDataService().loadData();
          self.setState({ login: true });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  render() {
    return (
      <section className="section is-flex-grow-1">
        {this.state.login ? <Redirect to="/user" /> : ""}
        <div className="container has-text-centered">
          <div className="box formBox">

            <h1 className="big-header">Login</h1>

            <form onSubmit={this.handelSignup}>
              <div className="field">
                <label className="label">E-Mail-Adresse</label>
                <div className="control has-icons-left has-icons-right">
                  <input className="input" type="email" placeholder="E-Mail-Adresse" name="email" />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label">Passwort</label>
                <p className="control has-icons-left">
                  <input className="input" type="password" placeholder="Passwort" autoComplete="true" name="password" />
                  <span className="icon is-small is-left">
                    <i className="fas fa-lock"></i>
                  </span>
                </p>
                <p className="help">{this.state.error}</p>
              </div>
              {this.state.credentialsError ? <div class="notification is-warning">
                <button className="delete" type="button" onClick={() => this.setState({ credentialsError: undefined })}></button>
                {this.state.credentialsError}
              </div> : ""}
              <div className="field is-grouped">
                <button className="button is-link">Login</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
