import React from 'react';
import Cookies from 'universal-cookie';
import Autocomplete from './reusableComponents/autocomplete';

import "./home.css";
import { getUserDataService } from '../userDataService';
import MultilineTextarea from './reusableComponents/textarea';

class Home extends React.Component {
  state = {
    error: undefined,
    text: undefined,
    token: undefined,
    kittens: []
  };

  constructor(props) {
    super(props);

    this.numberOfGuests = React.createRef();

    this.searchLocations.bind(this);
  }

  setData = (data) => {
    this.setState(() => ({
      text: data.text,
      kittens: data.image
    }));
  }

  getMembersData = () => {
    fetch('/DE_filtered.txt')
      .then(response => response.text())
      .then(data => {
        console.log(data);
        let rows = data.split('\n').map(r => r.split('\t'));
        this.setState({ geoLoc: rows });
      });
  }

  // componentDidUpdate(nextProps, nextState) {
  //   this.getMembersData();
  // }

  componentWillMount() {
    this.getMembersData();
  }

  selectCity(cityName) {
    const selection = this.state.geoLoc.find(r => r[0] === cityName);
    const zoom = Math.min(11, parseFloat(selection[3] + 1) * 2.5);
    this.setState({selectedLocation: [selection[1], selection[2], zoom, cityName]});
    getUserDataService().updateCity(cityName, selection[1], selection[2], zoom);
  }

  searchLocations() {
    if(this.state.selectedLocation){
      getUserDataService().search(this.props.history, this.state.selectedLocation[3], this.state.selectedLocation[1], this.state.selectedLocation[0], this.state.selectedLocation[2], parseInt(this.numberOfGuests.current.value));
    }
  }

  render() {
    return (
      <section className="section is-medium is-flex-grow-1 main-container">
        <div className="main-content-back" style={{backgroundImage: "url(\"./media/back01.jpg\")"}}></div>
        <div class="card column is-one-third-desktop is-half main-search-form">
          <div class="card-content">
            <div class="content">
              <h1 className="title is-4">Eure perfekte Hochzeitslocation</h1>
              <div class="field">
                <label class="label">Wo?</label>
                {this.state.geoLoc ? <Autocomplete placeholder="Ort" width="100%" onSelection={(sel) => this.selectCity(sel)} data={this.state.geoLoc.map(r => r[0])} /> : ""}
              </div>
              <div class="field">
                <label class="label">Wie viele Gäste?</label>
                <input ref={this.numberOfGuests} className="input" type="number" placeholder="Anzahl Gäste"/>
              </div>
              <button className="button is-primary is-fullwidth" onClick={() => {this.searchLocations()}}>suchen</button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
