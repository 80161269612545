import React from 'react';
import { Redirect } from 'react-router-dom';
import { BASE_PATH } from '../utils';

import './signup.css'


class Signup extends React.Component {
  state = {
    error: undefined
  };

  constructor(props) {
    super(props);

    this.password1 = React.createRef();
    this.password2 = React.createRef();
    this.email = React.createRef();

    this.emailChanged = this.emailChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.handelSignup = this.handelSignup.bind(this);
  }

  handelSignup = (e, props) => {
    e.preventDefault();

    const formElements = e.target.elements;
    const firstName = formElements.firstName.value.trim();
    const lastName = formElements.lastName.value.trim();
    const email = formElements.email.value.trim();
    const password = formElements.password.value.trim();
    const password2 = formElements.password2.value.trim();
    const bd_day = formElements.bd_day.value.trim();
    const bd_month = formElements.bd_month.value.trim();
    const bd_year = formElements.bd_year.value.trim();
    const captcha = formElements.captcha.value.trim();
    let error = false;

    if (firstName.length < 1) {
      this.setState({ firstNameError: true });
      error = true;
    } else {
      this.setState({ firstNameError: undefined });
    }
    if (lastName.length < 1) {
      this.setState({ lastNameError: true });
      error = true;
    } else {
      this.setState({ lastNameError: undefined });
    }
    if (password !== password2) {
      this.setState({ password2Error: "Die Passwörter stimmen nicht überein." });
      error = true;
    } else {
      this.setState({ password2Error: undefined });
    }
    if (!(this.emailChanged())) {
      error = true;
    }
    if (password.length < 8) {
      this.setState({ password1Error: "Das Passwort muss mindestens 8 Zeichen lang sein." });
      error = true;
    } else {
      this.setState({ password1Error: undefined });
    }
    if (bd_day.length < 1) {
      this.setState({ bdDayError: true });
      error = true;
    } else {
      this.setState({ bdDayError: undefined });
    }
    if (bd_month.length < 1) {
      this.setState({ bdMonthError: true });
      error = true;
    } else {
      this.setState({ bdMonthError: undefined });
    }
    if (bd_year.length < 4) {
      this.setState({ bdYearError: true });
      error = true;
    } else {
      this.setState({ bdYearError: undefined });
    }

    if (error) { return; }

    const data = {
      "email": email,
      "first_name": firstName,
      "last_name": lastName,
      "password": password,
      "birthday": bd_day + "." + bd_month + "." + bd_year,
      "captcha": captcha
    };
    var fd = new FormData();
    //very simply, doesn't handle complete objects
    for (var i in data) {
      fd.append(i, data[i]);
    }
    // // Submit to server
    const self = this;
    fetch(BASE_PATH + '/signup.php', {
      method: 'POST',
      body: fd
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        if (data.error) {
          switch (data.code) {
            case 1:
              self.setState({ emailError: "Diese E-Mail-Adresse ist bereits mit einem Account verknüpft." });
              break;
            case 5:
              self.setState({ captchaError: "Das eingegebene Catcha war falsch." });
              break;
            default:
              break;
          }
        }else if(data.success){
          self.setState({ registered: true });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  emailChanged(ev) {
    const email = this.email.current.value;
    if (validateEmail(email)) {
      this.setState({ emailError: undefined });
      return true;
    } else {
      this.setState({ emailError: "Die E-Mail-Adresse hat kein gültiges Format!" });
      return false;
    }
  }

  passwordChanged(ev) {
    let passwordSuccess = undefined;
    if (this.password1.current.value.length < 8) {
      this.setState({ password1Error: "Das Passwort muss mindestens 8 Zeichen lang sein." });
    } else {
      this.setState({ password1Error: undefined });
      if (this.password1.current.value !== this.password2.current.value) {
        this.setState({ password2Error: "Die Passwörter stimmen nicht überein!" });
      } else {
        this.setState({ password2Error: undefined });
        passwordSuccess = true;
      }
    }
    if (this.state.passwordSuccess !== passwordSuccess) {
      this.setState({ passwordSuccess: passwordSuccess });
    }
    return passwordSuccess === true;
  }

  render() {
    if (this.state.registered) {
      return <div className="main-content">
        <section className="section">
          <div className="container">
            <div className="box formBox">

              <h1 className="big-header has-text-centered  pb-4">Registrierung erfolgreich</h1>
              <div>Der Login mit den angegebenen Nutzerdaten ist nun freigeschalten.</div>
              <a href="#" onClick={() => this.props.history.push("/login")}>Zum Login &rarr;</a>
            </div></div></section></div>;
    }
    return (
      <div className="main-content">
        <section className="section">
          <div className="container">
            <div className="box formBox">

              <h1 className="big-header has-text-centered  pb-4">Registrierung</h1>

              <form onSubmit={this.handelSignup}>
                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <label className="label">Vorname</label>
                      <div className="control">
                        <input className={"input" + (this.state.firstNameError ? " is-danger " : "")} type="text" placeholder="Vorname" name="firstName" />
                      </div>
                    </div>
                  </div>
                  <div className="column is-mobile">
                    <div className="field">
                      <label className="label">Nachname</label>
                      <div className="controlclassName">
                        <input className={"input" + (this.state.lastNameError ? " is-danger " : "")} type="text" placeholder="Nachname" name="lastName" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <label className="label">E-mail Adresse</label>
                      <div className="control has-icons-left has-icons-right">
                        <input ref={this.email} className={"input " + (this.state.emailError ? " is-danger " : "")} type="email" placeholder="E-mail Adresse" onChange={this.emailChanged} name="email" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <p className="help is-danger">{this.state.emailError}</p>
                    </div>
                  </div>
                </div>

                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <label className="label">Passwort</label>
                      <p className="control has-icons-left">
                        <input ref={this.password1} className={"input" + (this.state.passwordSuccess ? " is-success " : "") + (this.state.password1Error ? " is-danger " : "")} onChange={this.passwordChanged} type="password" placeholder="Passwort" autoComplete="true" name="password" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </p>
                      <p className="help is-danger">{this.state.password1Error}</p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label">Passwort (Wdh.)</label>
                      <p className="control has-icons-left">
                        <input ref={this.password2} className={"input" + (this.state.passwordSuccess ? " is-success " : "") + (this.state.password2Error ? " is-danger " : "")} onChange={this.passwordChanged} type="password" placeholder="Passwort (Wiederholung)" autoComplete="true" name="password2" />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock"></i>
                        </span>
                      </p>
                      <p className="help is-danger">{this.state.password2Error}</p>
                    </div>
                  </div>
                </div>

                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <label className="label">Geburtsdatum</label>
                      <div className="birthday-container">
                        <input type="number" name="bd_day" className={"birthday-item input" + (this.state.bdDayError ? " is-danger " : "")} placeholder="TT"></input>
                        <input type="number" name="bd_month" className={"birthday-item input" + (this.state.bdMonthError ? " is-danger " : "")} placeholder="MM"></input>
                        <input type="number" name="bd_year" className={"birthday-item input" + (this.state.bdYearError ? " is-danger " : "")} placeholder="JJJJ"></input>
                      </div>
                      <p className="help is-danger">{this.state.error}</p>
                    </div>
                  </div>
                </div>

                <div className="columns is-mobile">
                  <div className="column">
                    <div className="field">
                      <label className="label">Sicherheitsabfrage</label>
                      <iframe src={BASE_PATH+"/captcha/index.php"}  scrolling="no" className="captcha"></iframe>
                      <input className={"input " + (this.state.captchaError ? " is-danger " : "")} type="text" placeholder="Sicherheitscode vom Bild" name="captcha" />
                      <p className="help is-danger">{this.state.captchaError}</p>
                      <p className="help">Gib den Text des obenstehenden Bildes ein um dich als Mensch auszuwei&szlig;en.</p>
                    </div>
                  </div>
                </div>

                <div className="field is-grouped">
                  <div className="control">
                    <button className="button is-link">Kostenlos registrieren</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        { this.props.isAuthenticated ? <Redirect to='/' /> : null}
      </div>
    );
  }
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default Signup;
