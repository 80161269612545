import React from 'react';
import './locationentry.css';
import { PROPERTY_MAP} from '../../utils';

class LocationEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    const location = this.props.location;

    let imageStyle = {};
    if(location.images.length > 0){
      imageStyle["backgroundImage"] = "url(\"/location_image.php?id="+location.images[0].id+"&thumb\")";
    }else{
      imageStyle["backgroundImage"] = "url(\"/media/no_image.jpg\")";
    }

    let tags = PROPERTY_MAP.filter(p => location.meta[p.key] === 1).map((p,i) => i>6?"":(i===6?<p> ... </p>:<span class="tag">{p.desc}</span>));

    let costSection = <p className="location-entry-cost-section"><span style={{
      display: "block",
      fontSize: "1.5rem",
      textAlign: "right"}}
      >?? - ?? €</span>Kosten pro Gast</p>;

    if(location.estimated_cost_base_min){
      let per_guest_min = location.estimated_cost_per_guest_min?location.estimated_cost_per_guest_min:0;
      let per_guest_max = location.estimated_cost_per_guest_max?location.estimated_cost_per_guest_max:0;
      let base_min = location.estimated_cost_base_min?location.estimated_cost_base_min:0;
      let base_max = location.estimated_cost_base_max?location.estimated_cost_base_max:0;

      if(this.props.numberOfGuests){
        costSection = <p className="location-entry-cost-section"><span style={{
          display: "block",
          fontSize: "1.5rem",
          textAlign: "right"}}
    >{Math.floor(base_min/(this.props.numberOfGuests)+per_guest_min)} - {Math.ceil(base_max/(this.props.numberOfGuests)+per_guest_max)} €</span> pro Gast</p>
      }else{
        costSection = <p className="location-entry-cost-section"><span style={{
        display: "block",
        fontSize: "1.5rem",
        textAlign: "right"}}
    >Anzahl Gäste</span> unbekannt</p>;
      }
    }

    return (
      <div onClick={() => { this.props.locationSelected() }} className={"card location-list-entry " + ((this.props.active || this.state.hover) ? "has-background-link-light" : "")} onMouseEnter={() => { this.setState({ hover: true }); if (this.props.onEnter) { this.props.onEnter(); } }} onMouseLeave={() => { this.setState({ hover: false }); if (this.props.onEnter) { this.props.onLeave(); } }}>
        <div className="location-media-container" style={imageStyle}></div>
        <div className="location-info-container card-content">
        
          <p className="title is-6">{location.name}</p>
          <div className="location-entry-content">
            <div class="tags is-flex-grow-1 location-entry-tags">
              {tags}
            </div>
            {costSection}
          </div>
          
        </div>
      </div>
    );
  }
}

export default LocationEntry;
